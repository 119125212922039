<template>
  <div class="main-content">
    <avue-crud
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      :page.sync="page"
      @on-load="onLoad"
    >
      <template #header>
        <div>
          <div class="flex flex-wrap align-items marginBottom20">
            <div class="flex align-items marginRight10 marginBottom10">
              <span class="span marginRight10 width70 textAlignRight"
                >分类名称 :
              </span>
              <div class="width300">
                <el-input
                  v-model="searchData.name"
                  placeholder="请输入"
                  clearable
                  maxlength="20"
                ></el-input>
              </div>
            </div>
            <div class="flex align-items marginRight10 marginBottom10">
              <el-button size="medium" type="primary" @click="handleSearch"
                >搜索</el-button
              >
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <el-button size="medium" type="info" @click="handleReset"
                >重置</el-button
              >
            </div>

            <div class="flex align-items marginRight10 marginBottom10">
              <el-button size="medium" type="primary" @click="handleCreate"
                >新增</el-button
              >
            </div>
          </div>
        </div>
      </template>
      <template slot="image" slot-scope="{ row }">
        <el-image
          style="width: 80px; height: 80px"
          :src="row.image"
          :preview-src-list="[row.image]"
        >
        </el-image>
      </template>

      <template slot="menu" slot-scope="{ row }">
        <!-- <el-button
          type="text"
          size="mini"
          icon="el-icon-view"
          @click="handleDetails(row)"
          >详情</el-button
        > -->
        <el-button type="text" size="mini" @click="handleEdit(row)"
          >编辑</el-button
        >
        <el-button
          type="text"
          size="mini"
          @click="handleDelete(row)"
          style="color: #ff001f !important"
          >删除</el-button
        >
      </template>
    </avue-crud>
    <el-dialog
      v-if="dialogVisible"
      :title="`${form.id ? '修改软件工具分类' : '新增软件工具分类'}`"
      :visible.sync="dialogVisible"
      width="40%"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <avue-form ref="form" v-model="form" :option="formOption">
        <template slot="name">
          <el-input
            v-model="form.name"
            maxlength="20"
            placeholder="请输入"
            onkeyup="this.value=this.value"
            @blur="salaryDianChange($event, 'name')"
            type="text"
            clearable
            :disabled="isDetails"
          />
        </template>

        <template slot="menuForm">
          <el-button @click="handleClose">
            {{ isDetails ? "关闭" : "取消" }}</el-button
          >
          <el-button
            type="primary"
            @click="submit(form)"
            :loading="showLoadingForm"
            >确定</el-button
          >
        </template>
      </avue-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  infoSoftwareClassifyList,
  infoSoftwareClassifyAdd,
  infoSoftwareClassifyUpdate,
  infoSoftwareClassifyDelete,
} from "@/api/info/softwareClassify";
import { mapGetters } from "vuex";

export default {
  name: "index",
  data() {
    return {
      button: {},
      showAuthority: true,
      isDetails: false,
      typeList: [],
      entryList: [],
      searchData: {
        name: "",
        projectId: "",
      },
      page: {
        total: 0,
        currentPage: 1,
        pageSize: 10,
      },
      tableData: [], //列表数据
      showLoading: false,
      showLoadingForm: false, //防重提交
      option: {
        title: "",
        titleSize: "h3",
        card: false,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: true, // 显示索引
        indexLabel: "序号", // 索引文字
        size: "small",
        selection: false, // 显示多选框
        page: true, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: "分类名称",
            prop: "name",
          },
          {
            label: "添加时间",
            prop: "createTime",
          },
        ],
      },

      dialogVisible: false,
      form: {
        image: [],
      },
      formOption: {
        submitBtn: false,
        submitText: "确定",
        emptyBtn: false,
        labelWidth: "120",
        row: true,
        column: [
          {
            label: "分类名称",
            prop: "name",
            type: "text",
            maxlength: 200,
            placeholder: "请输入",
            span: 24,
            rules: [
              {
                required: true,
                message: "请输入",
                trigger: "blur",
              },
            ],
          },
        ],
      },
      dialogPassWordVisible: false,
      dataUrl: "",
    };
  },
  created() {},
  methods: {
    //初始化
    onLoad() {
      const searchForm = {
        path: this.$route.path,
        searchData: this.searchData,
      };
      this.showLoading = true;
      infoSoftwareClassifyList({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...this.searchData,
      }).then((res) => {
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      });
    },
    // 添加
    handleCreate() {
      this.form.image = [];
      this.isDetails = false;
      this.form.id = "";
      this.dialogVisible = true;
    },
    // 查询
    handleSearch() {
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置
    handleReset() {
      // console.log('重置')
      // localStorage.removeItem("searchForm");
      this.searchData = {
        name: "",
      };
      this.page.currentPage = 1;
      this.onLoad();
    },
    //编辑
    async handleEdit(row) {
      // console.log(row)
      this.isDetails = false;
      this.form = { ...row, image: [row.image] };
      this.form.id = row.id;
      this.dialogVisible = true;
    },

    // 删除
    handleDelete(row) {
      // console.log('删除',row)
      this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        infoSoftwareClassifyDelete(row.id).then((res) => {
          if (res.code === 200) {
            this.$message.success("删除成功!");
            this.onLoad();
          }
        });
      });
    },
    // 重置form
    resetForm() {
      this.form = {
        id: "",
        type: "",
      };
    },

    // 关闭
    handleClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.resetForm();
      }, 500);
    },
    //输入
    salaryDianChange(e, t) {
      const value = e.target.value;
      this.form[t] = value;
    },
    // 提交
    submit(form) {
      this.$refs.form.$refs.form.validate((valid, done) => {
        if (valid) {
          if (this.showLoadingForm) {
            return;
          }
          this.showLoadingForm = true;
          // return
          if (form.id) {
            infoSoftwareClassifyUpdate({
              ...form,
            })
              .then((res) => {
                if (res.code === 200) {
                  this.$message.success("操作成功");
                  this.dialogVisible = false;
                  setTimeout(() => {
                    this.resetForm();
                  }, 500);
                  this.showLoadingForm = false;
                  this.onLoad();
                }
              })
              .catch(() => (this.showLoadingForm = false));
          } else {
            infoSoftwareClassifyAdd({
              ...form,
            })
              .then((res) => {
                if (res.code === 200) {
                  this.$message.success("操作成功");
                  this.dialogVisible = false;
                  setTimeout(() => {
                    this.resetForm();
                  }, 500);
                  this.showLoadingForm = false;
                  this.onLoad();
                }
              })
              .catch(() => (this.showLoadingForm = false));
          }
          // this.form.type = 1;
          // done()
        }
      });
    },
    // 查询
    handleSearch() {
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
    },

    handlePassWordClose() {
      this.dialogPassWordVisible = false;
    },
  },
};
</script>

<style scoped>
.copy {
  cursor: pointer;
  /* width: 60px; */
  margin-left: 15px;
  color: #66b1ff;
}
.tips {
  font-size: 20px;
  line-height: 1;
  margin-bottom: 30px;
}
</style>
