import { get, post, put, Delete } from "../axios";

// 软件或工具分类-列表
export const infoSoftwareClassifyList = (params) =>
  get("/web/info/softwareClassify/list", params);

// 软件或工具分类-新增
export const infoSoftwareClassifyAdd = (params) =>
  post("/web/info/softwareClassify/add", params);

// 软件或工具分类-编辑
export const infoSoftwareClassifyUpdate = (params) =>
  put("/web/info/softwareClassify/update", params);

// 软件或工具分类-删除
export const infoSoftwareClassifyDelete = (id) =>
  Delete("/web/info/softwareClassify/delete/" + id, "");
